import {
  Box,
  Container,
  createStyles,
  Divider,
  makeStyles,
  RootRef,
  Theme,
} from '@material-ui/core';
import { useEffect, useRef, VFC } from 'react';
import { Redirect } from 'react-router-dom';

import { CustomAppBar } from '../../components/CustomAppBar';
import { IntroContent } from '../../components/IntroContent';
import { IntroStepper } from '../../components/IntroStepper';
import { IntroStepperControls } from '../../components/IntroStepperControls';
import { config } from '../../config';
import { useIntroManager } from '../../hooks/useIntroManager';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',

      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    rootInner: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: 0,
    },
    stepper: { boxShadow: theme.shadows[3], zIndex: 1 },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'auto',
      minHeight: 0,
      height: '100%',
      padding: theme.spacing(4, 2),
      backgroundColor: theme.palette.background.paper,
    },
    divider: {
      margin: theme.spacing(4, 0, 0),
    },
    controls: {
      margin: theme.spacing(4, 0, 2, 0),
    },
  })
);

export const IntroPage: VFC = (): JSX.Element => {
  const classes = useStyles();

  const { stepper, completed } = useIntroManager();
  const { activeStep } = stepper;

  const scrollBox = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollBox && scrollBox.current) scrollBox.current.scrollTop = 0;
  }, [activeStep]);

  return (
    <Box className={classes.root} height="100%">
      <Box className={classes.rootInner}>
        {false && completed === true && <Redirect to={config.basePath} />}

        <CustomAppBar />

        <IntroStepper className={classes.stepper} />

        <RootRef rootRef={scrollBox}>
          <Box className={classes.content}>
            <Container maxWidth="md">
              <IntroContent />
              <Divider variant="fullWidth" className={classes.divider} />
              <IntroStepperControls className={classes.controls} />
            </Container>
          </Box>
        </RootRef>
      </Box>
    </Box>
  );
};
