import { useEffect, useState } from 'react';

import { useAdventure } from '../queries/useAdventure';

export const useSweepstakeStatus = () => {
  const enteredState = useState<boolean>(false);
  const adventure = useAdventure();

  useEffect(() => {
    if (adventure.data) {
      const enteredStorage = JSON.parse(
        localStorage.getItem('enteredSweepstakes') || '{}'
      );
      if (enteredStorage[adventure.data.sessionId]) {
        enteredState[1](true);
      }
    }
  }, [adventure.data]);

  const setEntered = () => {
    if (adventure.data) {
      enteredState[1](true);
      const enteredStorage = JSON.parse(
        localStorage.getItem('enteredSweepstakes') || '{}'
      );
      enteredStorage[adventure.data.sessionId] = true;
      localStorage.setItem(
        'enteredSweepstakes',
        JSON.stringify(enteredStorage)
      );
    }
  };

  return { entered: enteredState[0], setEntered };
};
