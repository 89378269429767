import {
  AppBar,
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useState, VFC } from 'react';

import { config } from '../config';
import { useAdventure } from '../queries/useAdventure';
import { DebugDialog } from './debug/DebugDialog';
import { HelpDialog } from './HelpDialog';
import { LogoutConfirmationDialog } from './LogoutConfirmationDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
    },
    titleBox: {
      flexGrow: 1,
    },
    title: {
      fontFamily: 'Orbitron',
      fontWeight: 500,
    },
    subtitle: {
      fontFamily: 'Orbitron',
      fontWeight: 500,
      fontSize: 10,
      display: 'block',
      marginBottom: -5,
    },
    logoutButton: {
      color: theme.palette.error.main,
    },
  })
);

export const CustomAppBar: VFC = (): JSX.Element => {
  const classes = useStyles();

  const adventure = useAdventure();

  const [debugMenuOpen, setDebugMenuOpen] = useState(false);
  const [
    logoutConfirmationDialogOpen,
    setLogoutConfirmationDialogOpen,
  ] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const [
    menuAnchorElement,
    setMenuAnchorElement,
  ] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorElement);

  const handleMenuClose = () => {
    setMenuAnchorElement(null);
  };

  return (
    <AppBar className={classes.root}>
      <Toolbar>
        <Box className={classes.titleBox}>
          {adventure.data ? (
            <>
              {!adventure.data.name.startsWith('(E)') && (
                <Typography className={classes.subtitle}>KEY ZONE</Typography>
              )}
              <Typography variant="h6" className={classes.title}>
                {adventure.data.name.replace(/^\(E\)/, '').trim()}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" className={classes.title}>
                KEY ZONE Schnitzeljagd
              </Typography>
            </>
          )}
        </Box>

        <div>
          <IconButton
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setMenuAnchorElement(event.currentTarget);
            }}
            color="inherit">
            <MoreIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchorElement}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                setHelpDialogOpen(true);
                handleMenuClose();
              }}>
              Hilfe
            </MenuItem>
            {!config.production && (
              <MenuItem
                onClick={() => {
                  setDebugMenuOpen(true);
                  handleMenuClose();
                }}>
                Debug
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                setLogoutConfirmationDialogOpen(true);
                handleMenuClose();
              }}
              className={classes.logoutButton}>
              Spiel verlassen
            </MenuItem>
          </Menu>
          <DebugDialog state={[debugMenuOpen, setDebugMenuOpen]} />
          <LogoutConfirmationDialog
            state={[
              logoutConfirmationDialogOpen,
              setLogoutConfirmationDialogOpen,
            ]}
          />
          <HelpDialog state={[helpDialogOpen, setHelpDialogOpen]} />
        </div>
      </Toolbar>
    </AppBar>
  );
};
