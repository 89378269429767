import { AdventureTask, useAdventure } from '../queries/useAdventure';
import { isTaskInRange } from '../util/inRange';
import { useGeolocation } from './useGeolocation';

export type AdventureTaskStatus = {
  inactive: boolean;
  inactiveDueToDependentTasks: boolean;
  inactiveReasons: string[];
};

export const useTaskStatus = (task: AdventureTask): AdventureTaskStatus => {
  const { position } = useGeolocation();
  const { data } = useAdventure();

  let activatedBefore = false;
  const activatedTasksStorage = JSON.parse(
    localStorage.getItem('activatedTasks') || '{}'
  );
  activatedBefore = !!activatedTasksStorage[task.id];
  let inactive = false;
  let inactiveDueToDependentTasks = false;
  const inactiveReasons = [];

  if (data) {
    if (isTaskInRange(position, task)) {
      // const activatedTasksStorage = JSON.parse(
      //   localStorage.getItem('activatedTasks') || '{}'
      // );
      activatedTasksStorage[task.id] = true;
      localStorage.setItem(
        'activatedTasks',
        JSON.stringify(activatedTasksStorage)
      );
    }
    if (!isTaskInRange(position, task) && !activatedBefore) {
      // check if task is in range
      inactive = true;
      inactiveReasons.push('Nicht in Reichweite');
    }

    // check if dependent tasks are solved correctly
    const dependentTasks = data.tasks.filter((x) =>
      task.dependentOf.includes(x.id)
    );
    dependentTasks.forEach((dTask) => {
      if (!dTask.solutionCorrect) {
        inactive = true;
        inactiveDueToDependentTasks = true;
        inactiveReasons.push(`Aufgabe "${dTask.name}" noch nicht gelöst`);
      }
    });

    // check if counteractive dependent tasks are solved
    const counteractiveDependentTasks = data.tasks.filter((x) =>
      task.counteractiveDependentOf.includes(x.id)
    );
    counteractiveDependentTasks.forEach((cTask) => {
      if (cTask.solutionCorrect) {
        inactive = true;
        inactiveDueToDependentTasks = true;
        inactiveReasons.push(`Aufgabe "${cTask.name}" bereits gelöst`);
      }
    });
  }

  return { inactive, inactiveDueToDependentTasks, inactiveReasons };
};
