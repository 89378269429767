/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';

import { config } from '../config';
import { SweepstakeEnterInput } from '../dtos/sweepstake.dto';
import { useAuth } from '../hooks/useAuth';

export const useEnterSweepstake = () => {
  const { token } = useAuth();

  async function mutationFn(email: string) {
    const sweepstakeEnterInput: SweepstakeEnterInput = {
      email,
    };
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    };
    await axios
      .post(
        `${config.backendUrl}/sweepstake/enter`,
        sweepstakeEnterInput,
        axiosRequestConfig
      )
      .catch((error) => {
        if (error.response.status !== 200) {
          throw new Error('Connection error');
        }
      });
  }

  return useMutation((solution: string) => mutationFn(solution), {
    mutationKey: 'enter-sweepstake',
  });
};
