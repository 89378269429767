import mapboxgl from 'mapbox-gl';
import { ViewportProps } from 'react-map-gl';
import create from 'zustand';

type State = {
  map: mapboxgl.Map | null;
  setMap: (map: mapboxgl.Map) => void;
  mapViewportProps: ViewportProps;
  setMapViewportProps: (state: ViewportProps) => void;
  mapViewportPropsFn: (state: ViewportProps) => void;
  setMapViewportPropsFn: (fn: (state: ViewportProps) => void) => void;
  positionFixed: boolean;
  setPositionFixed: (state: boolean) => void;
};

export const useTaskMap = create<State>((set) => ({
  map: null,
  setMap: (map) => set({ map }),
  mapViewportProps: {
    latitude: 53.86947020861825,
    longitude: 10.68388107526508,
    zoom: 15,
  },
  setMapViewportProps: (mapViewportProps) => set({ mapViewportProps }),
  mapViewportPropsFn: () => {},
  setMapViewportPropsFn: (state) => set({ mapViewportPropsFn: state }),
  positionFixed: false,
  setPositionFixed: (state) => set({ positionFixed: state }),
}));
