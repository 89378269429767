/* eslint-disable react/jsx-props-no-spreading */
import { Typography } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab/';
import { VFC } from 'react';

import { useAdventure } from '../queries/useAdventure';

export type FinalSolutionAlertProps = AlertProps;

export const FinalSolutionAlert: VFC<FinalSolutionAlertProps> = ({
  ...rest
}: FinalSolutionAlertProps): JSX.Element => {
  const adventure = useAdventure();

  if (adventure.data && adventure.data.finalSolution !== null) {
    if (adventure.data.finalSolutionCorrect) {
      return (
        <Alert {...rest} severity="success">
          {`Gesamtlösung "${adventure.data.finalSolution}" ist korrekt!`}
        </Alert>
      );
    }
    return (
      <Alert {...rest} severity="error">
        <Typography>{`"${adventure.data.finalSolution}" war leider nicht die richtige Lösung. Versucht es noch ein weiteres Mal!`}</Typography>
      </Alert>
    );
  }
  return <></>;
};
