/* eslint-disable no-param-reassign */
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Dispatch, SetStateAction, VFC } from 'react';

import { useAuth } from '../hooks/useAuth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      padding: theme.spacing(2),
    },
    logoutButton: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  })
);

type LogoutConfirmationDialogState = [
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>
];

type LogoutConfirmationDialogProps = {
  state: LogoutConfirmationDialogState;
};

export const LogoutConfirmationDialog: VFC<LogoutConfirmationDialogProps> = ({
  state,
}: LogoutConfirmationDialogProps): JSX.Element => {
  const classes = useStyles();

  const [open, setOpen] = state;

  const { setToken } = useAuth();

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setToken(null);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Sitzung verlassen?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Willst du wirklich die aktuelle Sitzung verlassen? Um wieder beitreten
          zu können, muss erneut der Aktivierungscode eingegeben werden!
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color="default">
          Abbrechen
        </Button>
        <Button
          onClick={handleLogout}
          color="primary"
          variant="contained"
          autoFocus
          className={classes.logoutButton}>
          Verlassen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
