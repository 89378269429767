/* eslint-disable no-param-reassign */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Dispatch, SetStateAction, useEffect, useRef, VFC } from 'react';

import { useAdventure } from '../queries/useAdventure';
import { Markdown } from './Markdown';

type HelpDialogState = [
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>
];

type HelpDialogProps = {
  state: HelpDialogState;
};

export const HelpDialog: VFC<HelpDialogProps> = ({
  state,
}: HelpDialogProps): JSX.Element => {
  const [open, setOpen] = state;

  const { data } = useAdventure();

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper">
      <DialogTitle>Hilfe</DialogTitle>
      <DialogContent dividers>
        <DialogContentText ref={descriptionElementRef} tabIndex={-1}>
          <Typography color="textPrimary">
            {data ? <Markdown text={data.helpText} /> : <></>}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
