/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  BoxProps,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { VFC } from 'react';
import { Redirect } from 'react-router-dom';

import { useIntroManager } from '../hooks/useIntroManager';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
  })
);

type IntroStepperControlsProps = BoxProps;

export const IntroStepperControls: VFC<IntroStepperControlsProps> = ({
  ...rest
}: IntroStepperControlsProps): JSX.Element => {
  const classes = useStyles();

  const { completed, stepper } = useIntroManager();
  const { activeStep, next, back, steps } = stepper;

  return (
    <Box {...rest}>
      {completed ? (
        <>
          Leite weiter auf&nbsp; /app
          <Redirect to="/app" />
        </>
      ) : (
        <div>
          <div>
            <Button
              disabled={activeStep === 0}
              onClick={back}
              className={classes.button}>
              Zurück
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={next}
              className={classes.button}>
              {activeStep === steps.length - 1 ? "Los geht's" : 'Weiter'}
            </Button>
          </div>
        </div>
      )}
    </Box>
  );
};
