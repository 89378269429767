/* eslint-disable react/style-prop-object */
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { HTMLAttributes, useEffect, useMemo, useState, VFC } from 'react';
import ReactMapGL, {
  GeolocateControl,
  NavigationControl,
  ScaleControl,
  ViewportProps,
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Route, useRouteMatch } from 'react-router-dom';

import { config } from '../config';
import { useAdventure } from '../queries/useAdventure';
import { ErrorType, useErrorStore } from '../stores/useErrorStore';
import { useGeolocationStore } from '../stores/useGeolocationStore';
import { useTaskMap } from '../stores/useTaskMap';
import { TaskMapPilot } from './TaskMapPilot';
import { TaskMapToZustand } from './TaskMapToZustand';
import { TaskMarker } from './TaskMarker';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 0,
      overflow: 'hidden',
      // height: '100%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    map: {
      height: '100%',
    },
  })
);
export type TaskMapProps = HTMLAttributes<HTMLDivElement>;

export const TaskMap: VFC<TaskMapProps> = ({
  ...rest
}: TaskMapProps): JSX.Element => {
  const classes = useStyles();

  const adventure = useAdventure();

  const { setPosition, setError } = useGeolocationStore();

  const { setMapViewportPropsFn } = useTaskMap();

  const [vp, setVp] = useState<ViewportProps>({
    latitude: 53.86947020861825,
    longitude: 10.68388107526508,
    zoom: 15,
  });

  useEffect(() => {
    setMapViewportPropsFn(setVp);
  }, []);

  const markers = useMemo(
    () =>
      adventure.data
        ? adventure.data.tasks.map((task) => <TaskMarker task={task} />)
        : [],
    [adventure.data]
  );

  const { path } = useRouteMatch();

  const { mapboxApiToken, mapboxMapStyle } = config;

  const { removeError } = useErrorStore();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest}>
      <Box className={classes.root}>
        <ReactMapGL
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...vp}
          className={classes.map}
          width="100%"
          height="100%"
          onViewportChange={setVp}
          mapboxApiAccessToken={mapboxApiToken}
          mapStyle={mapboxMapStyle}
          asyncRender>
          <GeolocateControl
            style={{
              right: 10,
              top: 10,
            }}
            positionOptions={{ enableHighAccuracy: true }}
            showAccuracyCircle={false}
            trackUserLocation
            auto
            onGeolocate={(position: GeolocationPosition | null) => {
              setPosition(position);
              setError(null);
              removeError(ErrorType.NoGeoLocationService);
            }}
          />
          <Route path={`${path}/map/:taskId`}>
            <TaskMapPilot />
          </Route>
          <NavigationControl
            style={{
              right: 10,
              top: 50,
            }}
          />
          <ScaleControl
            maxWidth={100}
            unit="metric"
            style={{
              left: 10,
              bottom: 40,
            }}
          />
          <TaskMapToZustand />
          {markers}
        </ReactMapGL>
      </Box>
    </div>
  );
};
