import { useEffect, VFC } from 'react';

import { ErrorType, useErrorStore } from '../stores/useErrorStore';
import { useGeolocationStore } from '../stores/useGeolocationStore';

export const GeolocationCheck: VFC = (): JSX.Element => {
  const { setPosition, setError } = useGeolocationStore();
  const { addError, removeError } = useErrorStore();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        setPosition(position);
        removeError(ErrorType.NoGeoLocationService);
      },
      (error: GeolocationPositionError) => {
        setError(error);
        addError(ErrorType.NoGeoLocationService);
      },
      {}
    );
  }, []);

  return <></>;
};
