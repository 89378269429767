import dotenv from 'dotenv';

dotenv.config();

export const config = {
  basePath: process.env.PUBLIC_URL || '',
  production: process.env.NODE_ENV === 'production',
  backendUrl: process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000/user',
  mapboxApiToken: process.env.REACT_APP_MAPBOX_API_TOKEN || '',
  mapboxMapStyle:
    process.env.REACT_APP_MAPBOX_MAP_STYLE ||
    'mapbox://styles/mapbox/streets-v11',
};
