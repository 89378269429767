import { IsArray, IsNumber, IsString, ValidateNested } from 'class-validator';

export class ScoreBoardScore {
  @IsString() sessionId: string;

  @IsString() teamName: string;

  @IsNumber() score: number;
}

export class ScoreBoardGetInput {}

export class ScoreBoardGetResponse {
  @ValidateNested() @IsArray() scores: ScoreBoardScore[];
}

export class ScoreTasksGetInput {}

export class ScoreTasksGetTaskScore {
  @IsString() taskId: string;

  @IsString() taskName: string;

  @IsNumber() score: number;

  @IsNumber() maxScore: number;
}

export class ScoreTasksGetRespone {
  @IsString() taskScores: ScoreTasksGetTaskScore[];
}
