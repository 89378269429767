import { useEffect, useState } from 'react';

import { useAuthStore } from '../stores/useAuthStore';

export const useAuth = () => {
  const authStore = useAuthStore();

  const [localStorageChecked, setLocalStoragechecked] = useState(false);

  const setToken = (token: string | null) => {
    authStore.setToken(token);
    if (token === null) {
      localStorage.removeItem('authToken');
    } else {
      localStorage.setItem('authToken', token);
    }
  };

  useEffect(() => {
    const localToken = localStorage.getItem('authToken');
    if (localToken) {
      setToken(localToken);
    }
    setLocalStoragechecked(true);
  }, []);
  return {
    isAuth: !!authStore.token,
    token: authStore.token,
    setToken,
    localStorageChecked,
  };
};
