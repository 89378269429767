import { Box } from '@material-ui/core';
import { VFC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { useIntroManager } from '../hooks/useIntroManager';
import { useAdventure } from '../queries/useAdventure';
import { IntroPage } from './app/IntroPage';
import { OutroPage } from './app/OutroPage';
import { TasksPage } from './app/TasksPage';

export const AppPage: VFC = (): JSX.Element => {
  const { path } = useRouteMatch();

  const { completed } = useIntroManager();

  const { data } = useAdventure();

  return (
    <Box height="100%">
      <Switch>
        <Route path={`${path}/intro`}>
          <IntroPage />
        </Route>
        <Route path={`${path}/tasks`}>
          <TasksPage />
          {!!data && data.ended && <Redirect to={`${path}/outro`} />}
        </Route>
        <Route path={`${path}/outro`}>
          {data ? (
            <>
              {data.ended ? <OutroPage /> : <Redirect to={`${path}/tasks`} />}
            </>
          ) : (
            <></>
          )}
        </Route>
        <Route path={`${path}*`}>
          <Redirect to={`${path}/tasks`} />
        </Route>
      </Switch>
      {completed === false && <Redirect to={`${path}/intro`} />}
    </Box>
  );
};
