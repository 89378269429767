/* eslint-disable no-param-reassign */
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Dispatch, SetStateAction, VFC } from 'react';

import { useAuth } from '../../hooks/useAuth';
import { useResetTasks } from '../../mutations/debug/useResetTasks';

type DebugDialogState = [
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>
];

type DebugDialogProps = {
  state: DebugDialogState;
};

export const DebugDialog: VFC<DebugDialogProps> = ({
  state,
}: DebugDialogProps): JSX.Element => {
  const [open, setOpen] = state;

  const { setToken } = useAuth();

  const resetTasks = useResetTasks();

  const handleListItemClick = (value: string) => {
    switch (value) {
      case 'deauth':
        setToken(null);
        setOpen(false);
        break;
      case 'resetintro':
        localStorage.removeItem('intro');
        window.location.reload();
        break;
      // TEMPORARY
      case 'resettasks':
        resetTasks.mutateAsync().then(() => {
          setOpen(false);
        });

        break;
      default:
        break;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}>
      <DialogTitle>Debug Functions</DialogTitle>
      <List>
        <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick('deauth')}>
          <ListItemText primary="Deauthenticate" />
        </ListItem>
        <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick('resetintro')}>
          <ListItemText primary="Reset Intro" />
        </ListItem>
        <ListItem
          autoFocus
          button
          onClick={() => handleListItemClick('resettasks')}>
          <ListItemText primary="Reset Tasks" />
        </ListItem>
      </List>
    </Dialog>
  );
};
