import axios, { AxiosRequestConfig } from 'axios';
import { plainToClass } from 'class-transformer';
import { QueryObserverResult, useQuery } from 'react-query';

import { config } from '../config';
import { ScoreBoardGetResponse } from '../dtos/score.dto';
import { useAuth } from '../hooks/useAuth';

export type ScoreBoardScore = {
  sessionId: string;
  teamName: string;
  score: number;
};

export type ScoreBoard = {
  scores: ScoreBoardScore[];
};

/* const getLocalDemoAdventure = async (): Promise<Adventure> =>
  demoAdventure; */

export function useScoreBoard(): QueryObserverResult<ScoreBoard, Error> {
  const { token, setToken } = useAuth();

  const fetchAdventure = async (): Promise<ScoreBoard> => {
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
      },
    };

    let response;

    await axios
      .get(`${config.backendUrl}/score/board`, axiosRequestConfig)
      .then((res) => {
        response = res.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setToken(null);
        } else if (error.response.status !== 200) {
          throw new Error('Connection error');
        }
      });

    const typedResponse = plainToClass(ScoreBoardGetResponse, response);

    return typedResponse;
  };

  return useQuery<ScoreBoard, Error>('score-board', fetchAdventure, {
    refetchInterval: 3000,
  });
}
