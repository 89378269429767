/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';

import { config } from '../config';
import { TaskSolutionInput } from '../dtos/task.dto';
import { useAuth } from '../hooks/useAuth';
import { AdventureTask } from '../queries/useAdventure';

export const useSolveTask = (task: AdventureTask) => {
  const { token } = useAuth();

  async function mutationFn(solution: string) {
    const taskSolutionInput: TaskSolutionInput = {
      taskId: task.id,
      solution,
    };
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    };
    await axios
      .post(
        `${config.backendUrl}/task/solve`,
        taskSolutionInput,
        axiosRequestConfig
      )
      .catch((error) => {
        if (error.response.status !== 200) {
          throw new Error('Connection error');
        }
      });
  }

  return useMutation((solution: string) => mutationFn(solution), {
    mutationKey: 'solve-task',
  });
};
