import create from 'zustand';

export enum View {
  Map = 0,
  List = 1,
  Progress = 2,
  Scoreboard = 3,
}

type State = {
  view: number;
  setView: (view: number) => void;
  listScrollResetFlag: boolean;
  setListScrollResetFlag: (state: boolean) => void;
};

export const useTasksPageView = create<State>((set) => ({
  view: 0,
  setView: (view) => set({ view }),
  listScrollResetFlag: false,
  setListScrollResetFlag: (state: boolean) =>
    set({ listScrollResetFlag: state }),
}));
