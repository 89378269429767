import { useContext, useEffect, VFC } from 'react';
import { MapContext } from 'react-map-gl';

import { useTaskMap } from '../stores/useTaskMap';

export const TaskMapToZustand: VFC = (): JSX.Element => {
  const { map } = useContext(MapContext);

  const { setMap } = useTaskMap();

  useEffect(() => {
    setMap(map);
  }, []);

  return <></>;
};
