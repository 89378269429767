import {
  CircularProgress,
  createStyles,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from '@material-ui/core';
import { VFC } from 'react';

export type LoadingScreenProps = {
  visible: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 100000,
      opacity: 1,
      transition: '400ms',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backdrop: {
      backgroundColor: `${theme.palette.primary.main}!important`,
    },
    grid: {
      color: 'white',
      outline: 0,
    },
    spinner: {
      color: '#fff',
    },
  })
);

export const LoadingScreen: VFC<LoadingScreenProps> = ({
  visible,
}: LoadingScreenProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.root}
      open={visible}
      closeAfterTransition
      BackdropProps={{ className: classes.backdrop }}>
      <Fade in={visible}>
        <Grid
          container
          className={classes.grid}
          direction="column"
          alignItems="center"
          justify="center"
          spacing={2}>
          <Grid item>
            <CircularProgress className={classes.spinner} />
          </Grid>
          <Grid item>
            <Typography>Lade Schnitzeljagd...</Typography>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};
