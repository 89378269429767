import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { VFC } from 'react';

import { useIntroManager } from '../hooks/useIntroManager';
import { useAdventure } from '../queries/useAdventure';
import { Markdown } from './Markdown';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

export const IntroContent: VFC = (): JSX.Element => {
  const classes = useStyles();
  const { data } = useAdventure();
  const { stepper } = useIntroManager();
  const { activeStep } = stepper;

  return (
    <>
      {activeStep === 0 && (
        <div className={classes.root}>
          <Markdown
            text={data ? data.introContent.safetyInformationText : ''}
          />
        </div>
      )}
      {activeStep === 1 && (
        <div className={classes.root}>
          <Markdown text={data ? data.introContent.gameInformationText : ''} />
        </div>
      )}
    </>
  );
};
