import create from 'zustand';

type State = {
  watchId: number | null;
  setWatchId: (id: number | null) => void;
  position: GeolocationPosition | null;
  setPosition: (pos: GeolocationPosition | null) => void;
  error: GeolocationPositionError | null;
  setError: (pos: GeolocationPositionError | null) => void;
};

export const useGeolocationStore = create<State>((set) => ({
  watchId: null,
  setWatchId: (id) => set({ watchId: id }),
  position: null,
  setPosition: (pos) => set({ position: pos }),
  error: null,
  setError: (err) => set({ error: err }),
}));
