/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';

import { config } from '../config';
import { AdventureFinalSolutionInput } from '../dtos/adventure.dto';
import { useAuth } from '../hooks/useAuth';

export const useSolveAdventure = () => {
  const { token } = useAuth();

  async function mutationFn(finalSolution: string) {
    const adventureFinalSolutionInput: AdventureFinalSolutionInput = {
      finalSolution,
    };
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    };
    await axios
      .post(
        `${config.backendUrl}/adventure/solve`,
        adventureFinalSolutionInput,
        axiosRequestConfig
      )
      .catch((error) => {
        if (error.response.status !== 200) {
          throw new Error('Connection error');
        }
      });
  }

  return useMutation((finalSolution: string) => mutationFn(finalSolution), {
    mutationKey: 'solve-adventure',
  });
};
