import { FC, PropsWithChildren } from 'react';

import { StyledThemeProvider } from '../theming/context';
import { ApiProvider } from './ApiProvider';

export const Providers: FC<PropsWithChildren<{}>> = ({
  children,
}: PropsWithChildren<{}>): JSX.Element => (
  <StyledThemeProvider>
    <ApiProvider>{children}</ApiProvider>
  </StyledThemeProvider>
);
