import {
  Box,
  Card,
  CardContent,
  Container,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { HTMLAttributes, VFC } from 'react';

import { useAdventure } from '../queries/useAdventure';
import { useScoreBoard } from '../queries/useScoreBoard';
import { useScoreTasks } from '../queries/useScoreTasks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 1),
      maxHeight: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    rootInner: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export type TaskScoreboardProps = HTMLAttributes<HTMLDivElement>;

export const TaskScoreboard: VFC<TaskScoreboardProps> = ({
  ...rest
}: TaskScoreboardProps): JSX.Element => {
  const classes = useStyles();

  const adventureQuery = useAdventure();
  const scoreBoardQuery = useScoreBoard();
  const scoreTasksQuery = useScoreTasks();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest}>
      <Box className={classes.root}>
        {!adventureQuery.isLoading &&
          adventureQuery.data &&
          !scoreBoardQuery.isLoading &&
          scoreBoardQuery.data &&
          !scoreTasksQuery.isLoading &&
          scoreTasksQuery.data && (
            <Container maxWidth="sm" className={classes.rootInner}>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Card>
                    <CardContent>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="h6">
                            {`Team ${adventureQuery.data.teamName}`}
                          </Typography>

                          <List>
                            {scoreTasksQuery.data.taskScores
                              .sort((a, b) => b.score - a.score)
                              .map((taskScore) => (
                                <ListItem>
                                  <Grid container>
                                    <Grid item xs={8}>
                                      <Typography>
                                        {taskScore.taskName}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{ textAlign: 'right' }}>
                                      <Typography>
                                        {taskScore.score}
                                        <Typography
                                          variant="caption"
                                          color="textSecondary">
                                          {`/${taskScore.maxScore}`}
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              ))}

                            <Divider />
                            <ListItem>
                              <Grid container>
                                <Grid item xs={8}>
                                  <Typography>Summe</Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{ textAlign: 'right' }}>
                                  <Typography>
                                    {scoreTasksQuery.data.taskScores.reduce(
                                      (score, taskScore) =>
                                        score + taskScore.score,
                                      0
                                    )}
                                    <Typography
                                      variant="caption"
                                      color="textSecondary">
                                      {`/${scoreTasksQuery.data.taskScores.reduce(
                                        (score, taskScore) =>
                                          score + taskScore.maxScore,
                                        0
                                      )}`}
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card>
                    <CardContent>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="h6">Rangliste</Typography>

                          <List>
                            {scoreBoardQuery.data.scores
                              .sort(
                                (scoreA, scoreB) => scoreB.score - scoreA.score
                              )
                              .filter((value, index) => index < 10)
                              .map((score) => (
                                <ListItem>
                                  <Grid container>
                                    <Grid item xs={8}>
                                      <Typography>{score.teamName}</Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{ textAlign: 'right' }}>
                                      <Typography>{score.score}</Typography>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              ))}
                          </List>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          )}
      </Box>
    </div>
  );
};
