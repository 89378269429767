import {
  Box,
  Card,
  CardContent,
  Container,
  createStyles,
  Grid,
  LinearProgress,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { HTMLAttributes, VFC } from 'react';

import { useScore } from '../hooks/useScore';
import { useAdventure } from '../queries/useAdventure';
import { FinalSolutionAlert } from './FinalSolutionAlert';
import { FinalSolutionForm } from './FinalSolutionForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 1),
      maxHeight: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    rootInner: {
      display: 'flex',
      flexDirection: 'column',
    },
    taskSolutionCard: { padding: theme.spacing(1) },
    taskSolutionCorrectCard: { backgroundColor: theme.palette.success.light },
    taskSolutionWrongCard: { backgroundColor: theme.palette.error.light },
  })
);

export type TaskProgressProps = HTMLAttributes<HTMLDivElement>;

export const TaskProgress: VFC<TaskProgressProps> = ({
  ...rest
}: TaskProgressProps): JSX.Element => {
  const classes = useStyles();

  const { data, isLoading } = useAdventure();

  const score = useScore();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest}>
      <Box className={classes.root}>
        <Container maxWidth="sm" className={classes.rootInner}>
          {!isLoading && !!data && (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="h6">Fortschritt</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {`${
                            data.tasks.filter((task) => task.solutionCorrect)
                              .length
                          } von ${
                            data.tasks.length
                          } Aufgaben erfolgreich bearbeitet`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <LinearProgress
                          variant="buffer"
                          valueBuffer={Math.round(
                            (data.tasks.filter((task) => task.solution !== null)
                              .length /
                              data.tasks.length) *
                              100
                          )}
                          value={Math.round(
                            (data.tasks.filter((task) => task.solutionCorrect)
                              .length /
                              data.tasks.length) *
                              100
                          )}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <Typography variant="h6">Punkte</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{score}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Typography variant="h6" gutterBottom>
                          Gesamtlösung
                        </Typography>
                        <Typography variant="body1">
                          {data.finalSolutionDescriptionText}
                          {/* Die Lösungsbuchstaben der einzelnen Aufgaben ergeben
                          in der richtigen Reihenfolge die Gesamtlösung: */}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          {data.tasks.map(
                            (task) =>
                              task.solution && (
                                <Grid item>
                                  <Card
                                    className={clsx(
                                      classes.taskSolutionCard,
                                      task.solutionCorrect &&
                                        classes.taskSolutionCorrectCard,
                                      !task.solutionCorrect &&
                                        classes.taskSolutionWrongCard
                                    )}>
                                    <Typography>{task.solution}</Typography>
                                  </Card>
                                </Grid>
                              )
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <FinalSolutionForm />
                      </Grid>
                      <Grid item>
                        <FinalSolutionAlert />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </div>
  );
};
