import create from 'zustand';

export enum ErrorType {
  NoGeoLocationService,
}

type State = {
  activeErrors: ErrorType[];
  addError: (error: ErrorType) => void;
  removeError: (error: ErrorType) => void;
};

export const useErrorStore = create<State>((set) => ({
  activeErrors: [],
  addError: (error) =>
    set((state) => {
      state.activeErrors.push(error);
    }),
  removeError: (error) =>
    set((state) => {
      state.activeErrors.filter((value) => value !== error);
    }),
}));
