import {
  IsArray,
  IsBoolean,
  IsDate,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';

export class AdventureInput {}

export class AdventureTaskLocationResponse {
  @IsNumber() lat: number;

  @IsNumber() lng: number;
}

export class AdventureTaskHintResponse {
  @IsString() id: string;

  @IsString() text: string;
}

export class AdventureTaskResponse {
  @IsString() id: string;

  @IsArray() dependentOf: string[];

  @IsArray() counteractiveDependentOf: string[];

  @IsString() @IsOptional() solution: string | null;

  @IsString() solutionCorrect: boolean;

  @IsString() name: string;

  @IsString() displayId: string;

  @IsString() @IsOptional() thumbnailUrl: string | undefined;

  @IsString() imageUrl: string;

  @IsString() description: string;

  @IsString() shortDescription: string;

  @IsOptional() audioUrl: string | undefined;

  @IsOptional() previewAudioUrl: string | undefined;

  @IsObject() @ValidateNested() location: AdventureTaskLocationResponse;

  @IsNumber() radius: number;

  @IsString() @IsOptional() additionalInfo: string | undefined;

  @IsBoolean() infoPoint: boolean;

  @IsArray() hints: AdventureTaskHintResponse[];
}

export class AdventureIntroContentResponse {
  @IsString() safetyInformationText: string;

  @IsString() gameInformationText: string;
}

export class AdventureResponse {
  @IsString() id: string;

  @IsString() sessionId: string;

  @IsString() name: string;

  @IsDate() activationTime: Date;

  @IsString() @IsOptional() teamName: string | undefined;

  @IsObject() @ValidateNested() introContent: AdventureIntroContentResponse;

  @IsArray() @ValidateNested() tasks: AdventureTaskResponse[];

  @IsString() helpText: string;

  @IsString() finalSolutionDescriptionText: string;

  @IsString() outroText: string;

  @IsBoolean() outroShowSweepstake: boolean;

  @IsString() @IsOptional() finalSolution: string | null;

  @IsBoolean() finalSolutionCorrect: boolean;

  @IsBoolean() ended: boolean;

  @IsString() introCartoonImageUrl: string;

  @IsString() outroCartoonImageUrl: string;

  @IsString() introGreeting: string;

  @IsString() introAddress: string;
}

export class AdventureFinalSolutionInput {
  @IsString() finalSolution: string;
}

export class AdventureFinalSolutionResponse {}
