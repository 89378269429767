import { useGeolocationStore } from '../stores/useGeolocationStore';

export type Geolocation = {
  position: GeolocationPosition | null;
  error: GeolocationPositionError | null;
};

export const useGeolocation = (): Geolocation => {
  const { position, error } = useGeolocationStore();
  return { position, error };
};
