/* eslint-disable react/jsx-closing-bracket-location */
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { FormEventHandler, useEffect, useState, VFC } from 'react';
import { Redirect } from 'react-router-dom';

import { Markdown } from '../components/Markdown';
import { ActivationResponse } from '../dtos/activation.dto';
import { useAuth } from '../hooks/useAuth';
import { useActivate } from '../mutations/useActivate';
import { useSetTeamName } from '../mutations/useSetTeamName';
import { useAdventure } from '../queries/useAdventure';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      backgroundImage: 'url(./images/activation-background.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      height: '100%',
      width: '100%',
    },
    cartoon: {
      height: 200,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      margin: theme.spacing(1),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  })
);

export const ActivationPage: VFC = (): JSX.Element => {
  const classes = useStyles();

  const { isAuth, setToken } = useAuth();
  const { data, refetch } = useAdventure();

  const [teamName, setTeamName] = useState<string | undefined>(undefined);
  const [teamNameSet, setTeamNameSet] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);

  const activate = useActivate();

  const setTeamNameMutation = useSetTeamName();

  const [activationCode, setActivationCode] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isAuth && teamNameSet && !!data)
      setInterval(() => {
        setRedirect(true);
      }, 3000);
  }, [isAuth, teamNameSet, data]);

  const handleSubmit: FormEventHandler = (evt) => {
    evt.preventDefault();
    activate
      .mutateAsync(activationCode)
      .then((response: ActivationResponse) => {
        setToken(response.token);
        setTeamName(response.teamName);
        setTeamNameSet(!!response.teamName);
        setError(false);
        refetch();
      })
      .catch(() => {
        setError(true);
      });
  };

  const handleSetTeamName = () => {
    if (teamName) {
      setTeamNameMutation.mutateAsync(teamName).then(() => {
        setTeamNameSet(true);
      });
    }
  };

  return (
    <>
      {redirect && <Redirect to="/" />}
      <Grid
        container
        spacing={0}
        direction="column"
        className={classes.root}
        justify="center">
        <Container maxWidth="sm">
          <Card>
            {!isAuth && (
              <CardContent>
                <Typography variant="h4" component="h1" gutterBottom>
                  Aktivierung
                </Typography>
                <Typography variant="body1" component="h1" gutterBottom>
                  Um eure Tour zu starten, gebt den Code ein, den ihr per E-Mail
                  erhalten habt.
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Das Spiel kann gleichzeitig auf mehreren Geräten gestartet
                  werden.
                </Typography>
                <form onSubmit={handleSubmit} className={classes.form}>
                  <TextField
                    variant={isAuth ? 'standard' : 'filled'}
                    value={activationCode}
                    onChange={
                      (event) => setActivationCode(event.target.value)
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    error={error}
                    helperText={error ? 'Ungültiger Aktivierungscode' : ''}
                    label="Aktivierungscode"
                    disabled={isAuth}
                  />
                  {!isAuth && (
                    <Button type="submit" variant="outlined" size="large">
                      Spiel starten
                    </Button>
                  )}
                </form>
              </CardContent>
            )}
            {isAuth && !data && (
              <CardContent>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                  spacing={2}>
                  <Grid item xs={12}>
                    <CircularProgress style={{ margin: 20 }} />
                  </Grid>
                </Grid>
              </CardContent>
            )}
            {isAuth && !teamNameSet && !!data && (
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      className={classes.cartoon}
                      style={{
                        backgroundImage: `url(${data.introCartoonImageUrl})`,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Markdown text={data.introGreeting} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom>
                      Gebt hier euren Teamnamen ein:
                    </Typography>
                    <TextField
                      variant="filled"
                      label="Teamname"
                      value={teamName}
                      onChange={(event) => setTeamName(event.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleSetTeamName}
                      fullWidth>
                      Absenden
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            )}
            {isAuth && teamNameSet && !!data && (
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      className={classes.cartoon}
                      style={{
                        backgroundImage: `url(${data.introCartoonImageUrl})`,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4">{data.introAddress}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Willkommen Team&nbsp;
                      {teamName}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Container>
      </Grid>
    </>
  );
};
