import { Typography, useTheme } from '@material-ui/core';
import { Room, WhereToVote } from '@material-ui/icons';
import { useState, VFC } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { useRouteMatch } from 'react-router-dom';

import { useTaskStatus } from '../hooks/useTaskStatus';
import { AdventureTask } from '../queries/useAdventure';
import { RedirectButton } from './RedirectButton';

export type TaskMarkerProps = {
  task: AdventureTask;
};

export const TaskMarker: VFC<TaskMarkerProps> = ({ task }: TaskMarkerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const {
    inactive,
    inactiveDueToDependentTasks,
    inactiveReasons,
  } = useTaskStatus(task);
  const theme = useTheme();

  const { path } = useRouteMatch();

  return inactiveDueToDependentTasks ? (
    <></>
  ) : (
    <>
      <Marker
        latitude={task.location.lat}
        longitude={task.location.lng}
        offsetLeft={-25}
        offsetTop={-50}
        onClick={() => {
          setPopupOpen(!popupOpen);
        }}
        onDragStart={() => {
          setPopupOpen(false);
        }}>
        {task.solutionCorrect ? (
          <WhereToVote
            style={{
              cursor: 'pointer',
              height: 50,
              width: 50,
              filter: 'drop-shadow(1px 0px 1px rgba(0,0,0,0.4))',
              fill: !inactive
                ? theme.palette.success.main
                : theme.palette.success.light,
            }}
          />
        ) : (
          <Room
            style={{
              cursor: 'pointer',
              height: 50,
              width: 50,
              filter: 'drop-shadow(1px 0px 1px rgba(0,0,0,0.4))',
              fill: (() => {
                if (!inactive && !!task.solution) {
                  return theme.palette.error.main;
                }
                if (!!inactive && !!task.solution) {
                  return theme.palette.error.light;
                }
                if (!inactive && !task.solution) {
                  return task.infoPoint
                    ? '#0066db'
                    : theme.palette.primary.main;
                }
                return theme.palette.action.disabled;
              })(),
            }}
          />
        )}
      </Marker>
      {popupOpen ? (
        <div style={{ zIndex: 100, position: 'relative' }}>
          <Popup
            latitude={task.location.lat}
            longitude={task.location.lng}
            closeButton
            closeOnClick
            onClose={() => setPopupOpen(false)}
            anchor="bottom"
            offsetTop={-50}>
            <Typography variant="h6" align="center">
              {task.name}
            </Typography>
            {!inactive && (
              <RedirectButton
                variant="text"
                color="primary"
                to={`${path}/list`}>
                Zur Aufgabe
              </RedirectButton>
            )}
            <Typography variant="caption">
              {inactiveReasons.join(', ')}
            </Typography>
          </Popup>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
