import { useEffect, useState, VFC } from 'react';
import { FlyToInterpolator } from 'react-map-gl';
import { Redirect, useParams } from 'react-router-dom';

import { useAdventure } from '../queries/useAdventure';
import { useTaskMap } from '../stores/useTaskMap';

export type TaskMapPilotProps = {};

export const TaskMapPilot: VFC<TaskMapPilotProps> = (): JSX.Element => {
  // @ts-ignore
  const { taskId } = useParams();

  const adventure = useAdventure();
  const { map, mapViewportPropsFn } = useTaskMap();

  const [flightStarted, setFlightStarted] = useState(false);
  useEffect(() => {
    if (!!adventure.data && !!map && map.loaded()) {
      const task = adventure.data?.tasks.find((item) => item.id === taskId);
      if (task) {
        mapViewportPropsFn({
          latitude: task.location.lat,
          longitude: task.location.lng,
          zoom: 16,
          transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
          transitionDuration: 500,
        });
        setFlightStarted(true);
      }
    }
  }, [adventure.data]);

  return flightStarted ? <Redirect to="/app/tasks/map" /> : <></>;
};
