import React, { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

export type ApiProviderProps = PropsWithChildren<{}>;

export const ApiProvider: FC<ApiProviderProps> = ({
  children,
}: PropsWithChildren<{}>): JSX.Element => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
