/* eslint-disable react/jsx-props-no-spreading */
import { Box, BoxProps } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React, { VFC } from 'react';

import { useIntroManager } from '../hooks/useIntroManager';

type IntroStrepperProps = BoxProps;

export const IntroStepper: VFC<IntroStrepperProps> = ({
  ...rest
}: IntroStrepperProps): JSX.Element => {
  const { stepper } = useIntroManager();
  const { activeStep, steps } = stepper;

  return (
    <Box {...rest}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
