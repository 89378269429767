import { Box } from '@material-ui/core';
import { VFC } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { ErrorCheck } from './components/ErrorCheck';
import { config } from './config';
import { ActivationPage } from './pages/ActivationPage';
import { AppPage } from './pages/AppPage';
import { ErrorPage } from './pages/ErrorPage';
import { AuthenticatedRoute } from './services/AuthenticatedRoute';
import { Providers } from './services/Providers';
import 'reflect-metadata';
import 'es6-shim';

export const App: VFC = (): JSX.Element => (
  <Providers>
    <Box height="100%">
      <BrowserRouter basename={config.basePath}>
        <ErrorCheck />
        <Switch>
          <Route path="/activate" component={ActivationPage} />
          <Route path="/error" component={ErrorPage} />
          <AuthenticatedRoute path="/app" component={AppPage} />
          <Redirect from="*" to="/app" />
        </Switch>
      </BrowserRouter>
    </Box>
  </Providers>
);
