/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Card,
  CardContent,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import union from 'lodash/union';
import { useMemo, VFC } from 'react';

import { CustomAppBar } from '../../components/CustomAppBar';
import { Markdown } from '../../components/Markdown';
import { OutroSweepstakeForm } from '../../components/OutroSweepstakeForm';
import { useScore } from '../../hooks/useScore';
import { useAdventure } from '../../queries/useAdventure';
import { useScoreBoard } from '../../queries/useScoreBoard';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    rootInner: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: 0,
    },
    stepper: { boxShadow: theme.shadows[3], zIndex: 1 },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'auto',
      minHeight: 0,
      height: '100%',
      padding: theme.spacing(4, 2),
      backgroundColor: theme.palette.background.paper,
    },
    points: {
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      color: 'white',
    },
    text: {
      marginBottom: theme.spacing(2),
    },
    cartoon: {
      height: 200,
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      margin: theme.spacing(1),
    },
  })
);

export const OutroPage: VFC = (): JSX.Element => {
  const classes = useStyles();

  const theme = useTheme();

  const adventure = useAdventure();
  const score = useScore();
  const { data: scoreBoard } = useScoreBoard();

  // const scoreBoard = {
  //   scores: [
  //     { sessionId: 'cl4ny21o005688u3d00x1asfd', teamName: 'blup1', score: 90 },
  //     { sessionId: 'cl4ny21o005688u3d00423rak', teamName: 'blup2', score: 80 },
  //     { sessionId: 'cl4ny21o305688u34230x1rak', teamName: 'blup3', score: 70 },
  //     { sessionId: 'clny21o305688u34230we1rak', teamName: 'blup4', score: 60 },
  //     { sessionId: 'cl4ny1o305688u342120x1rak', teamName: 'blup5', score: 50 },
  //     { sessionId: 'cl4n21o3005688u3d0112x1rak', teamName: 'blup9', score: 40 },
  //     { sessionId: 'cl4ny21o3005688u3d00121rak', teamName: 'blup6', score: 30 },
  //     { sessionId: 'XXXXXXXXXXXXXXXXXXXXX', teamName: 'blup', score: 85 },
  //   ],
  // };
  const calculateScoreDisplayScoreBoard = (
    scores: { sessionId: string; teamName: string; score: number }[]
  ) => {
    // sort scores
    const sortedScores = scores.sort(
      (entry1, entry2) => entry2.score - entry1.score
    );

    // add positions to entries
    const scoresWithPositions = scores.map((entry, index) => ({
      ...entry,
      position: index,
    }));

    // own position
    const ownPosition = sortedScores.findIndex(
      (entry) => entry.teamName === adventure.data?.teamName
    );

    const top3 = scoresWithPositions.slice(0, 3);

    const neihgbourhood = scoresWithPositions.slice(
      Math.max(ownPosition - 2, 0),
      ownPosition + 3
    );
    return union(top3, neihgbourhood);
  };

  const scoreBoardDisplay = useMemo(
    () =>
      scoreBoard !== undefined
        ? calculateScoreDisplayScoreBoard(scoreBoard.scores)
        : [],
    [scoreBoard, calculateScoreDisplayScoreBoard]
  );

  return (
    <Box className={classes.root} height="100%">
      <Box className={classes.rootInner}>
        <CustomAppBar />

        <Box className={classes.content}>
          <Container maxWidth="xs">
            {adventure.data ? (
              <Grid container direction="column" spacing={6}>
                <Grid item xs={12}>
                  <Box
                    className={classes.cartoon}
                    style={{
                      backgroundImage: `url(${adventure.data.outroCartoonImageUrl})`,
                    }}
                  />
                </Grid>
                <Grid item>
                  <div className={classes.text}>
                    <Markdown text={adventure.data.outroText} />
                  </div>
                </Grid>
                <Grid item>
                  <Card className={classes.points}>
                    <CardContent>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Typography variant="h6">Erreichte Punkte</Typography>
                          <Typography variant="h2">{score}</Typography>
                        </Grid>
                        <Grid item>
                          <Container maxWidth="xs">
                            <Typography variant="h6" gutterBottom>
                              Rangliste
                            </Typography>
                            {scoreBoardDisplay.map((entry, index) => (
                              <>
                                <Grid
                                  container
                                  style={
                                    entry.position % 2 === 0
                                      ? {
                                          background:
                                            theme.palette.primary.light,
                                          padding: theme.spacing(0.5),
                                        }
                                      : { padding: theme.spacing(0.5) }
                                  }>
                                  <Grid
                                    item
                                    xs={2}
                                    style={{
                                      paddingLeft: theme.spacing(0.5),
                                    }}>
                                    <Typography variant="body1" align="left">
                                      {entry.position + 1}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography variant="body1" align="left">
                                      {entry.teamName}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    style={{
                                      paddingRight: theme.spacing(0.3),
                                    }}>
                                    <Typography variant="body1" align="right">
                                      {entry.score}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                {index < scoreBoardDisplay.length - 1 &&
                                scoreBoardDisplay[index + 1].position -
                                  entry.position >
                                  1 ? (
                                  // eslint-disable-next-line react/jsx-indent
                                  <Grid container justify="center">
                                    <Grid item>
                                      <Typography variant="h5">···</Typography>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                {index === scoreBoardDisplay.length - 1 &&
                                scoreBoardDisplay.length > 2 &&
                                scoreBoardDisplay[index - 2].teamName ===
                                  adventure.data.teamName ? (
                                  // eslint-disable-next-line react/jsx-indent
                                  <Grid container justify="center">
                                    <Grid item>
                                      <Typography variant="h5">···</Typography>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </>
                            ))}
                          </Container>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  {adventure.data.outroShowSweepstake && (
                    <OutroSweepstakeForm />
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Container>
        </Box>
      </Box>
    </Box>
  );
};
