import { useEffect, VFC } from 'react';
import { useHistory } from 'react-router-dom';

import { useErrorStore } from '../stores/useErrorStore';

export const ErrorCheck: VFC = (): JSX.Element => {
  const { activeErrors } = useErrorStore();

  const history = useHistory();

  useEffect(() => {
    if (activeErrors.length) {
      history.replace('/error');
    }
  }, [activeErrors.length]);

  return <></>;
};
