/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';

export type AuthenticatedRouteProps = RouteProps & {};

export const AuthenticatedRoute: FC<AuthenticatedRouteProps> = ({
  children,
  ...rest
}: AuthenticatedRouteProps): JSX.Element => {
  const { isAuth, localStorageChecked } = useAuth();

  return (
    <>
      {localStorageChecked ? (
        isAuth ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Route {...rest}>{children}</Route>
        ) : (
          <Redirect to="/activate" />
        )
      ) : (
        <></>
      )}
    </>
  );
};
