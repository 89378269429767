import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState, VFC } from 'react';

import { useSweepstakeStatus } from '../hooks/useSweepstakeStatus';
import { useEnterSweepstake } from '../mutations/useEnterSweepstake';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
    },
  })
);

export const OutroSweepstakeForm: VFC = (): JSX.Element => {
  const classes = useStyles();

  const sweepstakeStatus = useSweepstakeStatus();

  const [submissionTried, setSubmissionTried] = useState<boolean>(false);

  const [email, setEmail] = useState<string>();
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState<boolean>(
    false
  );

  const enterSweepstake = useEnterSweepstake();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSubmissionTried(true);
    if (email) {
      if (privacyPolicyAccepted) {
        enterSweepstake.mutateAsync(email).then(() => {
          sweepstakeStatus.setEntered();
        });
      }
    }
  };

  return (
    <Grid container direction="column" spacing={2} className={classes.root}>
      {sweepstakeStatus.entered ? (
        <Grid item>
          <Alert severity="success">
            Du hast dich erfolgreich für das Gewinnspiel registriert.
          </Alert>
        </Grid>
      ) : (
        <>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              Gewinnspielteilnahme
            </Typography>
            <Typography variant="body2">
              Um an unserem Gewinnspiel teilnehmen zu können, müsst Du unserer
              Datenschutzerklärung zustimmen und uns Deine E-Mail-Adresse
              übermitteln.
            </Typography>
          </Grid>
          <Grid item>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="E-Mail-Adresse"
                    variant="filled"
                    type="email"
                    value={email}
                    error={submissionTried && !email}
                    required
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={8} xs={12}>
                  <FormControl
                    required
                    error={submissionTried && !privacyPolicyAccepted}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={privacyPolicyAccepted}
                            onChange={() => {
                              setPrivacyPolicyAccepted(!privacyPolicyAccepted);
                            }}
                            color="primary"
                          />
                        }
                        label={
                          <Typography>
                            Ich habe die&#160;
                            <a
                              href="https://key-zone.de/datenschutz/"
                              target="_blank"
                              rel="noreferrer">
                              Datenschutzerklärung
                            </a>
                            &#160;gelesen und akzeptiere sie.
                          </Typography>
                        }
                      />
                    </FormGroup>
                    {submissionTried && !privacyPolicyAccepted && (
                      <FormHelperText>
                        Sie haben die Datenschutzerklärung nicht akzeptiert.
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth>
                    Teilnehmen
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </>
      )}
    </Grid>
  );
};
