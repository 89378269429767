/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Collapse,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons/';
import {
  Dispatch,
  FormEventHandler,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
  VFC,
} from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useSolveTask } from '../mutations/useSolveTask';
import { AdventureTask } from '../queries/useAdventure';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: { width: '100%' },
    button: {
      width: '100%',
      height: '100%',
    },
  })
);

type TaskModalSolutionFormProps = {
  task: AdventureTask;
  modalOpenState: [open: boolean, setOpen: Dispatch<SetStateAction<boolean>>];
} & HTMLAttributes<HTMLFormElement>;

export const TaskModalSolutionForm: VFC<TaskModalSolutionFormProps> = ({
  task,
  modalOpenState,
  ...rest
}: TaskModalSolutionFormProps): JSX.Element => {
  const classes = useStyles();
  const [solution, setSolution] = useState('');

  const [justSubmitted, setJustSubmitted] = useState(false);

  const solveTask = useSolveTask(task);

  const queryClient = useQueryClient();

  const history = useHistory();

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    solveTask.mutateAsync(solution).then(() => {
      queryClient.invalidateQueries('adventure');
      setJustSubmitted(true);
    });
  };
  const { path } = useRouteMatch();

  useEffect(() => {
    if (justSubmitted && task.solutionCorrect) {
      setTimeout(() => {
        modalOpenState[1](false);
        history.push(`${path}/map`);
      }, 2000);
    }
  }, [justSubmitted, task.solutionCorrect]);

  return (
    <Collapse in={!task.solutionCorrect}>
      <form {...rest} onSubmit={handleSubmit} autoComplete="false">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          spacing={2}>
          <Grid item xs={8}>
            <TextField
              id={`solution-${task.id}`}
              label="Lösung"
              variant="outlined"
              size="small"
              className={classes.text}
              onChange={(event) => {
                setSolution(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.button}
              endIcon={<SendIcon />}>
              Absenden
            </Button>
          </Grid>
        </Grid>
      </form>
    </Collapse>
  );
};
