import { useEffect, VFC } from 'react';

import { useTasksPageView, View } from '../stores/useTasksPageView';

export type TaskPageViewSetterProps = {
  view: View;
};

export const TaskPageViewSetter: VFC<TaskPageViewSetterProps> = ({
  view,
}: TaskPageViewSetterProps): JSX.Element => {
  const { setView } = useTasksPageView();

  useEffect(() => {
    setView(view);
  }, [view]);

  return <></>;
};
