/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';
import { plainToClass } from 'class-transformer';
import { useMutation } from 'react-query';

import { config } from '../config';
import { ActivationInput, ActivationResponse } from '../dtos/activation.dto';

export const useActivate = () => {
  async function mutationFn(activationCode: string) {
    const activationInput: ActivationInput = {
      activationCode,
    };
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { status, data } = await axios.post(
      `${config.backendUrl}/activate`,
      activationInput,
      axiosRequestConfig
    );
    if (status !== 200) {
      throw new Error('Connection error');
    }

    const typedResponse = plainToClass(ActivationResponse, data);

    return typedResponse;
  }

  return useMutation((activationCode: string) => mutationFn(activationCode), {
    mutationKey: 'activate',
  });
};
