import { distance } from '@turf/turf';

import { AdventureTask } from '../queries/useAdventure';

export function distanceToTarget(
  currentPosition: Pick<
    GeolocationCoordinates,
    'latitude' | 'longitude'
  > | null,
  targetPosition: Pick<GeolocationCoordinates, 'latitude' | 'longitude'> | null
): number {
  if (!currentPosition || !targetPosition) return -1;

  return distance(
    [currentPosition.latitude, currentPosition.longitude],
    [targetPosition.latitude, targetPosition.longitude],
    { units: 'meters' }
  );
}

export const distanceToTask = (
  position: GeolocationPosition | null,
  task: AdventureTask
) => {
  if (position) {
    return distanceToTarget(position.coords, {
      latitude: task.location.lat,
      longitude: task.location.lng,
    });
  }
  return -1;
};

export function isTargetInRange(
  currentPosition: Pick<
    GeolocationCoordinates,
    'latitude' | 'longitude'
  > | null,
  targetPosition: Pick<GeolocationCoordinates, 'latitude' | 'longitude'> | null,
  targetRadius: number
): boolean {
  if (!currentPosition || !targetPosition) return false;

  return distanceToTarget(currentPosition, targetPosition) <= targetRadius;
}

export const isTaskInRange = (
  position: GeolocationPosition | null,
  task: AdventureTask
) => {
  if (position) {
    return !!isTargetInRange(
      position.coords,
      {
        latitude: task.location.lat,
        longitude: task.location.lng,
      },
      task.radius
    );
  }
  return false;
};
