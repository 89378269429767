import create from 'zustand';

type State = {
  activeStep: number;
  steps: readonly string[];
  handleNext: () => void;
  handleBack: () => void;
};

export const useIntroStepperStore = create<State>((set) => ({
  activeStep: 0,
  steps: ['Sicherheitshinweise', 'Spiel-Infos'],
  handleNext: () =>
    set((state: State) => ({
      activeStep: state.activeStep + 1,
    })),
  handleBack: () => set((state) => ({ activeStep: state.activeStep - 1 })),
}));
