/* eslint-disable react/prop-types */
import { Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import rehypeReact from 'rehype-react';
import rehypeStringify from 'rehype-stringify';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';

type MarkdownProps = {
  text: string;
};

export const Markdown: VFC<MarkdownProps> = ({ text }: MarkdownProps) => (
  <>
    {
      unified()
        .use(remarkParse)
        .use(remarkGfm)
        .use(remarkRehype)
        .use(rehypeStringify)
        .use(rehypeReact, {
          createElement: React.createElement,
          passNode: true,
          components: {
            p: ({ children }) => (
              <Typography gutterBottom>{children}</Typography>
            ),
            span: ({ children }) => <Typography>{children}</Typography>,
            h1: ({ children }) => (
              <Typography variant="h1" gutterBottom>
                {children}
              </Typography>
            ),
            h2: ({ children }) => (
              <Typography variant="h2" gutterBottom>
                {children}
              </Typography>
            ),
            h3: ({ children }) => (
              <Typography variant="h3" gutterBottom>
                {children}
              </Typography>
            ),
            h4: ({ children }) => (
              <Typography variant="h4" gutterBottom>
                {children}
              </Typography>
            ),
            h5: ({ children }) => (
              <Typography variant="h5" gutterBottom>
                {children}
              </Typography>
            ),
            h6: ({ children }) => (
              <Typography variant="h6" gutterBottom>
                {children}
              </Typography>
            ),
          },
        })
        .processSync(text).result
    }
  </>
);
