import axios, { AxiosRequestConfig } from 'axios';
import { plainToClass } from 'class-transformer';
import { QueryObserverResult, useQuery } from 'react-query';

import { config } from '../config';
import { ScoreTasksGetRespone } from '../dtos/score.dto';
import { useAuth } from '../hooks/useAuth';

export type ScoreTasksTask = {
  taskId: string;
  taskName: string;
  score: number;
  maxScore: number;
};

export type ScoreTasks = {
  taskScores: ScoreTasksTask[];
};

/* const getLocalDemoAdventure = async (): Promise<Adventure> =>
  demoAdventure; */

export function useScoreTasks(): QueryObserverResult<ScoreTasks, Error> {
  const { token, setToken } = useAuth();

  const fetchAdventure = async (): Promise<ScoreTasks> => {
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
      },
    };

    let response;

    await axios
      .get(`${config.backendUrl}/score/tasks`, axiosRequestConfig)
      .then((res) => {
        response = res.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setToken(null);
        } else if (error.response.status !== 200) {
          throw new Error('Connection error');
        }
      });

    const typedResponse = plainToClass(ScoreTasksGetRespone, response);

    return typedResponse;
  };

  return useQuery<ScoreTasks, Error>('score-tasks', fetchAdventure, {
    refetchInterval: 3000,
  });
}
