import { Theme, ThemeProvider } from '@material-ui/core/styles';
import { FC, ReactNode, useState } from 'react';

import { Keyzone2021Theme } from './themes/keyzone2021';

export const StyledThemeProvider: FC<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }): JSX.Element => {
  const [theme] = useState<Theme>(Keyzone2021Theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
