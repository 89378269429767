/* eslint-disable react/jsx-closing-bracket-location */
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  makeStyles,
} from '@material-ui/core';
import {
  List as ListIcon,
  Map as MapIcon,
  AssignmentTurnedIn as ProgressIcon,
  EmojiEvents as TrophyIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { useEffect, VFC } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';

import { CustomAppBar } from '../../components/CustomAppBar';
import { GeolocationCheck } from '../../components/GeolocationCheck';
import { LoadingScreen } from '../../components/LoadingScreen';
import { TaskList } from '../../components/TaskList';
import { TaskMap } from '../../components/TaskMap';
import { TaskPageViewSetter } from '../../components/TaskPageViewSetter';
import { TaskProgress } from '../../components/TaskProgress';
import { TaskScoreboard } from '../../components/TaskScoreboard';
import { useAdventure } from '../../queries/useAdventure';
import { useTaskMap } from '../../stores/useTaskMap';
import { useTasksPageView, View } from '../../stores/useTasksPageView';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',

    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  rootInner: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: 0,
    height: '100%',
    position: 'relative',
  },
  condDiv: {
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
  bottomNav: {
    position: 'relative',
    width: '100%',
    flexShrink: 0,
  },
});

const Content: VFC = (): JSX.Element => {
  const classes = useStyles();
  const { view } = useTasksPageView();
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}/map`}>
          <TaskPageViewSetter view={View.Map} />
        </Route>
        <Route path={`${path}/list`}>
          <TaskPageViewSetter view={View.List} />
        </Route>
        <Route path={`${path}/progress`}>
          <TaskPageViewSetter view={View.Progress} />
        </Route>
        <Route path={`${path}/scoreboard`}>
          <TaskPageViewSetter view={View.Scoreboard} />
        </Route>
      </Switch>
      <GeolocationCheck />
      <div className={classes.content}>
        <TaskList
          className={clsx(
            classes.condDiv,
            view !== View.List && [classes.hidden]
          )}
        />
        <TaskMap
          className={clsx(
            classes.condDiv,
            view !== View.Map && [classes.hidden]
          )}
        />
        <TaskProgress
          className={clsx(
            classes.condDiv,
            view !== View.Progress && [classes.hidden]
          )}
        />
        <TaskScoreboard
          className={clsx(
            classes.condDiv,
            view !== View.Scoreboard && [classes.hidden]
          )}
        />
      </div>
    </>
  );
};

export const TasksPage: VFC = (): JSX.Element => {
  const classes = useStyles();
  const { view } = useTasksPageView();
  const { path } = useRouteMatch();

  const { setPositionFixed } = useTaskMap();

  const adventure = useAdventure();

  useEffect(() => {
    setPositionFixed(false);
  }, [view]);

  return (
    <Box className={classes.root} height="100%">
      <div className={classes.rootInner}>
        <LoadingScreen visible={adventure.isLoading} />

        <CustomAppBar />

        <Content />

        <BottomNavigation className={classes.bottomNav} value={view} showLabels>
          <BottomNavigationAction
            component={Link}
            label="Karte"
            icon={<MapIcon />}
            to={`${path}/map`}
          />
          <BottomNavigationAction
            component={Link}
            label="Aufgaben"
            icon={<ListIcon />}
            to={`${path}/list`}
          />
          <BottomNavigationAction
            component={Link}
            label="Fortschritt"
            icon={<ProgressIcon />}
            to={`${path}/progress`}
          />
          <BottomNavigationAction
            component={Link}
            label="Rangliste"
            icon={<TrophyIcon />}
            to={`${path}/scoreboard`}
          />
        </BottomNavigation>
      </div>
    </Box>
  );
};
