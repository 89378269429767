/* eslint-disable react/jsx-props-no-spreading */
import { Typography } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab/';
import { VFC } from 'react';

import { AdventureTask } from '../queries/useAdventure';

export type TaskModalSolutionAlertProps = {
  task: AdventureTask;
} & AlertProps;

export const TaskModalSolutionAlert: VFC<TaskModalSolutionAlertProps> = ({
  task,
  ...rest
}: TaskModalSolutionAlertProps): JSX.Element => {
  if (task.solution !== null) {
    if (task.solutionCorrect) {
      return (
        <Alert {...rest} severity="success">
          {`Antwort "${task.solution}" ist korrekt!`}
        </Alert>
      );
    }
    return (
      <Alert {...rest} severity="error">
        <Typography>{`Antwort "${task.solution}" ist falsch!`}</Typography>
        <Typography variant="caption">
          Achtung: Jeder Fehlversuch kostet euch Punkte!
        </Typography>
      </Alert>
    );
  }
  return <></>;
};
