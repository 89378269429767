import {
  Box,
  Container,
  createStyles,
  makeStyles,
  RootRef,
  Theme,
} from '@material-ui/core';
import { HTMLAttributes, useEffect, useRef, useState, VFC } from 'react';

import { useAdventure } from '../queries/useAdventure';
import { useTasksPageView } from '../stores/useTasksPageView';
import { TaskListItem } from './TaskListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 1),
      maxHeight: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    rootInner: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

export type TaskListProps = HTMLAttributes<HTMLDivElement>;

export const TaskList: VFC<TaskListProps> = ({
  ...rest
}: TaskListProps): JSX.Element => {
  const classes = useStyles();
  const { isLoading, data } = useAdventure();

  const { listScrollResetFlag, setListScrollResetFlag } = useTasksPageView();

  const scrollBox = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listScrollResetFlag) {
      setListScrollResetFlag(false);
      if (scrollBox && scrollBox.current) {
        scrollBox.current.scrollTop = 0;
      }
    }
  }, [listScrollResetFlag]);

  const [visibleTasks, setVisibleTasks] = useState<number>(0);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest}>
      <RootRef rootRef={scrollBox}>
        <Box className={classes.root}>
          <Container maxWidth="sm" className={classes.rootInner}>
            {!isLoading && (
              <>
                {data && data.tasks
                  ? data.tasks.map((value) => (
                      // eslint-disable-next-line react/jsx-indent
                      <TaskListItem
                        key={value.id}
                        task={value}
                        setVisibleTasks={setVisibleTasks}
                      />
                    ))
                  : null}
              </>
            )}
            {visibleTasks === 0 && (
              <p style={{ width: '100%', textAlign: 'center' }}>
                Keine Aufgaben in Reichweite
              </p>
            )}
          </Container>
        </Box>
      </RootRef>
    </div>
  );
};
