/* eslint-disable no-nested-ternary */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Collapse,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import AudioPlayer from 'material-ui-audio-player';
import { Dispatch, SetStateAction, useState, VFC } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import { useUnlockHint } from '../mutations/useUnlockHint';
import { AdventureTask } from '../queries/useAdventure';
import { Markdown } from './Markdown';
import { TaskModalSolutionAlert } from './TaskModalSolutionAlert';
import { TaskModalSolutionForm } from './TaskModalSolutionForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: '100%',
      height: '100%',
      overflowY: 'auto',
    },
    container: {
      padding: 0,
      position: 'relative',
    },

    close: {
      position: 'fixed',
      top: 0,
      backgroundColor: '#ffffffaa',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      // filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,0.2))',
      '&:hover': {
        backgroundColor: '#ffffffaa',
      },
      '&:active': {
        backgroundColor: '#ffffffaa',
      },
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    image: {
      width: '100%',
      maxHeight: '50vh',
      objectFit: 'cover',
    },
    content: {
      padding: theme.spacing(2),
    },
    description: {
      '& p:first-child': {
        marginTop: 0,
      },
      '& p:last-child': {
        marginBottom: 0,
      },
      '& img': {
        maxWidth: '100%',
      },
    },
    solutionForm: {
      marginTop: theme.spacing(3),
    },
    solutionAlert: {
      marginTop: theme.spacing(2),
    },

    showMore: {
      marginLeft: 'auto',
      color: theme.palette.action.active,
    },
    showMoreIcon: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    showMoreIconOpen: {
      transform: 'rotate(180deg)',
    },
    moreActions: {
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
    },
    more: {
      backgroundColor: theme.palette.background.default,
    },
  })
);

const useAudioStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  progressTime: {
    color: theme.palette.text.secondary,
  },
  playIcon: {
    transition: '300ms',
  },
  pauseIcon: {
    transition: '300ms',
  },
  volumeIcon: {
    transition: '300ms',
  },
}));

export type TaskModalProps = {
  openState: [open: boolean, setOpen: Dispatch<SetStateAction<boolean>>];
  task: AdventureTask;
  closePath: string;
};

export const TaskModal: VFC<TaskModalProps> = ({
  openState,
  task,
  closePath,
}: TaskModalProps) => {
  const classes = useStyles();
  const [open] = openState;
  const [showMore, setShowMore] = useState(false);
  const [showHints, setShowHints] = useState(false);

  const handleClose = () => {
    setShowMore(false);
  };

  const handleShowMoreClick = () => {
    setShowMore(!showMore);
  };

  const handleShowHintsClick = () => {
    setShowHints(!showHints);
  };

  const queryClient = useQueryClient();
  const unlockHint = useUnlockHint();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <SimpleReactLightbox>
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Container maxWidth="sm" className={classes.container}>
              <IconButton
                component={Link}
                onClick={handleClose}
                className={classes.close}
                to={closePath}>
                <CloseIcon />
              </IconButton>
              <Card elevation={0}>
                <CardMedia
                  className={classes.media}
                  image={task.imageUrl || task.thumbnailUrl}
                  title={task.name}
                />
                <CardContent className={classes.content}>
                  <Typography variant="h6">{task.name}</Typography>
                  <SRLWrapper>
                    <Typography variant="body1" className={classes.description}>
                      <Markdown text={task.description} />
                    </Typography>
                  </SRLWrapper>
                  {task.audioUrl && (
                    <AudioPlayer
                      src={task.audioUrl}
                      spacing={2}
                      variation="default"
                      elevation={0}
                      volume={false}
                      useStyles={useAudioStyles}
                    />
                  )}
                  {task.infoPoint ? (
                    <></>
                  ) : (
                    <>
                      <TaskModalSolutionForm
                        className={classes.solutionForm}
                        modalOpenState={openState}
                        task={task}
                      />
                      <TaskModalSolutionAlert
                        className={classes.solutionAlert}
                        task={task}
                      />
                    </>
                  )}
                </CardContent>
                {task.hints.length > 0 && (
                  <>
                    <CardActions className={classes.moreActions}>
                      <Button
                        onClick={handleShowHintsClick}
                        className={classes.showMore}
                        endIcon={
                          <ExpandMoreIcon
                            className={clsx(classes.showMoreIcon, {
                              [classes.showMoreIconOpen]: showHints,
                            })}
                          />
                        }>
                        {showHints ? 'Hinweise ausblenden' : 'Hinweise'}
                      </Button>
                    </CardActions>
                    <Collapse in={showHints}>
                      <CardContent className={classes.more}>
                        <Grid container direction="column" spacing={2}>
                          {task.hints &&
                            task.hints.map((hint, index) => (
                              <Grid item>
                                <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                    <Typography variant="h6">
                                      {`Hinweis ${index + 1}`}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    {hint.text === '' ? (
                                      index === 0 ||
                                      task.hints[index - 1].text !== '' ? (
                                        <Button
                                          variant="outlined"
                                          onClick={() => {
                                            unlockHint
                                              .mutateAsync(hint.id)
                                              .then(() => {
                                                queryClient.invalidateQueries(
                                                  'adventure'
                                                );
                                              });
                                          }}>
                                          Freischalten
                                        </Button>
                                      ) : (
                                        <Button variant="outlined" disabled>
                                          Freischalten
                                        </Button>
                                      )
                                    ) : (
                                      <Typography variant="body1">
                                        {hint.text}
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </CardContent>
                    </Collapse>
                  </>
                )}
                {!!task.additionalInfo && (
                  <>
                    <CardActions className={classes.moreActions}>
                      <Button
                        onClick={handleShowMoreClick}
                        className={classes.showMore}
                        endIcon={
                          <ExpandMoreIcon
                            className={clsx(classes.showMoreIcon, {
                              [classes.showMoreIconOpen]: showMore,
                            })}
                          />
                        }>
                        {showMore ? 'Weniger anzeigen' : 'Mehr erfahren'}
                      </Button>
                    </CardActions>
                    <Collapse in={showMore}>
                      <CardContent className={classes.more}>
                        <Typography>
                          <Markdown text={task.additionalInfo || ''} />
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </>
                )}
              </Card>
            </Container>
          </Paper>
        </Fade>
      </SimpleReactLightbox>
    </Modal>
  );
};
