/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';

import { config } from '../../config';
import { useAuth } from '../../hooks/useAuth';

export const useResetTasks = () => {
  const { token } = useAuth();

  async function mutationFn() {
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    };
    const { status } = await axios.delete(
      `${config.backendUrl}/debug/resettasks`,
      axiosRequestConfig
    );
    if (status !== 200) {
      throw new Error('Connection error');
    }
  }

  return useMutation(() => mutationFn(), {
    mutationKey: 'solve-task',
  });
};
