import { useAdventure } from '../queries/useAdventure';
import { useScoreBoard } from '../queries/useScoreBoard';

export const useScore = () => {
  const scoreBoard = useScoreBoard();
  const adventure = useAdventure();

  return scoreBoard.data &&
    adventure.data &&
    adventure.data.sessionId &&
    scoreBoard.data.scores &&
    scoreBoard.data.scores.filter(
      (score) => score.sessionId === adventure.data.sessionId
    ).length
    ? scoreBoard.data.scores.filter(
        (score) => score.sessionId === adventure.data.sessionId
      )[0].score
    : 0;
};
