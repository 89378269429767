/* eslint-disable react/jsx-props-no-spreading */
import { Button, ButtonProps } from '@material-ui/core';
import { FC, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

export type RedirectButtonProps = {
  to: string;
  reload?: boolean;
} & PropsWithChildren<{}> &
  ButtonProps;

export const RedirectButton: FC<RedirectButtonProps> = ({
  to,
  reload,
  children,
  ...rest
}: RedirectButtonProps): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <Button
        {...rest}
        onClick={() => {
          history.push(to);
          if (reload) {
            history.go(0);
          }
        }}>
        {children}
      </Button>
    </>
  );
};
