import { IsJWT, IsOptional, IsString } from 'class-validator';

export class ActivationInput {
  @IsString() activationCode: string;
}

export class ActivationResponse {
  @IsString() @IsJWT() token: string;

  @IsString() @IsOptional() teamName: string | undefined;
}

export class ActivationTeamNamePutInput {
  @IsString() @IsOptional() teamName: string;
}

export class ActivationTeamNamePutResponse {}
