/* eslint-disable react/jsx-closing-bracket-location */
import {
  Card,
  CardContent,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import { VFC } from 'react';
import { Redirect } from 'react-router-dom';

import { RedirectButton } from '../components/RedirectButton';
import { ErrorType, useErrorStore } from '../stores/useErrorStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      backgroundImage: 'url(./images/error-background.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
      height: '100%',
      width: '100%',
    },
  })
);

export const ErrorPage: VFC = (): JSX.Element => {
  const classes = useStyles();
  const { activeErrors } = useErrorStore();

  return (
    <>
      {activeErrors.length === 0 && <Redirect to="/asdf" />}
      <Grid
        container
        spacing={0}
        direction="column"
        className={classes.root}
        justify="center">
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            {activeErrors.find(
              (error) => error === ErrorType.NoGeoLocationService
            ) !== undefined && (
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                          spacing={2}>
                          <Grid item xs={11}>
                            <Typography variant="h4">Kein Standort</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Grid container justify="flex-end">
                              <Grid item>
                                <LocationOffIcon
                                  fontSize="large"
                                  color="disabled"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          Dein aktueller Standort konnte nicht ermittelt werden.
                          Bitte stelle sicher, dass die Ortungsdienste deines
                          Gerätes aktiviert sind und die Standortfreigabe für
                          deinen Browser sowie diese Seite aktiviert ist.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <RedirectButton
                          to="/"
                          reload
                          variant="outlined"
                          color="default">
                          Erledigt
                        </RedirectButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <></>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};
