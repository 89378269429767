/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons/';
import { FormEventHandler, HTMLAttributes, useState, VFC } from 'react';
import { useQueryClient } from 'react-query';

import { useSolveAdventure } from '../mutations/useSolveAdventure';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: { width: '100%' },
    button: {
      width: '100%',
      height: '100%',
    },
  })
);

type FinalSolutionFormProps = HTMLAttributes<HTMLFormElement>;

export const FinalSolutionForm: VFC<FinalSolutionFormProps> = ({
  ...rest
}: FinalSolutionFormProps): JSX.Element => {
  const classes = useStyles();
  const [finalSolution, setFinalSolution] = useState('');

  const queryClient = useQueryClient();
  const solveAdventure = useSolveAdventure();

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    solveAdventure.mutateAsync(finalSolution).then(() => {
      queryClient.invalidateQueries('adventure');
    });
  };

  return (
    <form {...rest} onSubmit={handleSubmit} autoComplete="false">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
        spacing={2}>
        <Grid item xs={8}>
          <TextField
            label="Gesamtlösung"
            variant="outlined"
            size="small"
            className={classes.text}
            onChange={(event) => {
              setFinalSolution(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.button}
            endIcon={<SendIcon />}>
            Absenden
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
