import { useEffect, useState } from 'react';

import { useAdventure } from '../queries/useAdventure';
import { useIntroStepperStore } from '../stores/useIntroStepperStore';

export const useIntroManager = () => {
  const adventure = useAdventure();
  const [completed, setCompleted] = useState<boolean | null>(null);

  useEffect(() => {
    if (adventure.data) {
      const introCompletedStorage = JSON.parse(
        localStorage.getItem('introCompleted') || '{}'
      );
      setCompleted(!!introCompletedStorage[adventure.data.sessionId]);
    }
  }, [adventure.data]);

  const stepper = useIntroStepperStore();

  const handleNext = () => {
    if (stepper.activeStep === stepper.steps.length - 1) {
      if (adventure.data) {
        const introCompletedStorage = JSON.parse(
          localStorage.getItem('introCompleted') || '{}'
        );
        introCompletedStorage[adventure.data.sessionId] = true;
        localStorage.setItem(
          'introCompleted',
          JSON.stringify(introCompletedStorage)
        );
      }

      setCompleted(true);
      stepper.handleNext();
    } else {
      stepper.handleNext();
    }
  };

  return {
    completed,
    stepper: {
      activeStep: stepper.activeStep,
      steps: stepper.steps,
      next: handleNext,
      back: stepper.handleBack,
    },
  };
};
