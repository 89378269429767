import { Dispatch, SetStateAction, useEffect, VFC } from 'react';

export type TaskModalToggleProps = {
  open: boolean;
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
};

export const TaskModalToggle: VFC<TaskModalToggleProps> = ({
  open,
  openState,
}: TaskModalToggleProps) => {
  useEffect(() => {
    openState[1](open);
  }, [open]);

  return <></>;
};
