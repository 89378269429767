import { createMuiTheme, Theme } from '@material-ui/core/styles';

export const Keyzone2021Theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#40a738',
    },
    secondary: {
      main: '#ffb300',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          overflow: 'hidden',
        },
        body: {
          overflow: 'hidden',
          position: 'relative',
          margin: 0,
          padding: 0,
        },
      },
    },
  },
});
