import axios, { AxiosRequestConfig } from 'axios';
import { plainToClass } from 'class-transformer';
import { QueryObserverResult, useQuery } from 'react-query';

import { config } from '../config';
// eslint-disable-next-line import/no-cycle
// import { demoAdventure } from '../data/demoData';
import { AdventureResponse } from '../dtos/adventure.dto';
import { useAuth } from '../hooks/useAuth';

export type AdventureTaskLocation = {
  lat: number;
  lng: number;
};

export type AdventureTaskHint = {
  id: string;
  text: string;
};

export type AdventureTask = {
  id: string;
  dependentOf: string[];
  counteractiveDependentOf: string[];
  solution: string | null;
  solutionCorrect: boolean;
  name: string;
  displayId: string;
  thumbnailUrl: string | undefined;
  imageUrl: string;
  description: string;
  shortDescription: string;
  audioUrl: string | undefined;
  previewAudioUrl: string | undefined;
  location: AdventureTaskLocation;
  radius: number;
  additionalInfo: string | undefined;
  infoPoint: boolean;
  hints: AdventureTaskHint[];
};

export type AdventureIntroContent = {
  safetyInformationText: string;
  gameInformationText: string;
};

export type Adventure = {
  id: string;
  sessionId: string;
  name: string;
  activationTime: Date;
  teamName: string | undefined;
  introContent: AdventureIntroContent;
  tasks: AdventureTask[];
  helpText: string;
  outroText: string;
  outroShowSweepstake: boolean;
  finalSolutionDescriptionText: string;
  finalSolution: string | null;
  finalSolutionCorrect: boolean;
  ended: boolean;
  introCartoonImageUrl: string;
  outroCartoonImageUrl: string;
  introGreeting: string;
  introAddress: string;
};

export function useAdventure(): QueryObserverResult<Adventure, Error> {
  const { token, setToken } = useAuth();

  const fetchAdventure = async (): Promise<Adventure> => {
    const axiosRequestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
      },
    };

    let response;

    await axios
      .get(`${config.backendUrl}/adventure`, axiosRequestConfig)
      .then((res) => {
        response = res.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setToken(null);
        } else if (error.response.status !== 200) {
          throw new Error('Connection error');
        }
      });

    const typedResponse = plainToClass(AdventureResponse, response);

    return typedResponse;
  };

  return useQuery<Adventure, Error>('adventure', fetchAdventure, {
    refetchInterval: 3000,
  });
}
